import React from 'react'
import { Collapse } from 'antd'
import ProCard from '@ant-design/pro-card'
// import { query } from '@/pages/occupant/services';
// import { OccupantList } from '@/components/occupants';
import { CaretRightOutlined } from '@ant-design/icons'
import { Property } from '../../domain/property'
import { StandType } from '../../domain/stand-type'
import { MunicipalService } from '../../domain/municipal-service'
import { WardConsilor } from '../../domain/ward-consilor'
import PropertyInformation from '../property'
import EditAdultHouseholdProfile from '../../pages/household/EditAdultHouseHoldProfile'
import EditMinorhouseholdProfile from '../../pages/household/EditMinorHouseHoldProfile'
import GrantsHouseholdProfileForm from '../grand-profile'
import { Application } from '../../pages/applications/types'

const { Panel } = Collapse

type PropertyInformationProps = {
  updateOfflineApplication: (key: number, values: Application) => Promise<IDBValidKey | undefined>
  wardCounsilors: WardConsilor[]
  standTypes: StandType[]
  municipalServices: MunicipalService[]
  offlineApplication?: Application
}

const PropertyInformationForm = (props: PropertyInformationProps) => {
  const property = {} as Property

  return (
    <Collapse
      accordion
      bordered={false}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      className='site-collapse-custom-collapse'
    >
      <Panel header='Property Details' key='1'>
        <ProCard bordered={true} direction={'row'}>
          <PropertyInformation
            property={property}
            wardConsilors={props.wardCounsilors}
            standTypes={props.standTypes}
            offlineApplication={props.offlineApplication}
            municipalServices={props.municipalServices}
            updateOfflineApplication={props.updateOfflineApplication}
          />
        </ProCard>
      </Panel>
    </Collapse>
  )
}

export default PropertyInformationForm
