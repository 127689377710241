import ProForm, { ProFormText, ProFormSelect } from '@ant-design/pro-form'
import { Title } from '../../domain/enums/title'
import { Gender } from '../../domain/enums/gender'
import { Race } from '../../domain/enums/race'
import { Language } from '../../domain/enums/language'
import { useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PersonalInformation = () => {
  const canEdit = true
  const [currentGender, setCurrentGender] = useState(Object.fromEntries(Object.entries(Gender)))
  const [filterlist, setFilterList] = useState(Object.fromEntries(Object.entries(Gender)))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateGender = (value: any) => {
    const mList = {
      MALE: Gender.MALE,
    }
    if (value && value === Title.MR && filterlist.FEMALE) {
      setFilterList(mList)
      setCurrentGender(mList)
    }
    const fList = {
      FEMALE: Gender.FEMALE,
    }
    if (value && value !== Title.MR && filterlist.MALE) {
      setFilterList(fList)
      setCurrentGender(fList)
    }
    if (!value && (!currentGender.MALE || !currentGender.FEMALE)) {
      setCurrentGender(Object.fromEntries(Object.entries(Gender)))
    }
    return value
  }

  return (
    // <ProForm.Group title={`${'' ?? firstNames} ${'' ?? surname}`}>
    <ProForm.Group>
      <ProFormSelect
        name='title'
        label=' Title '
        valueEnum={Object.fromEntries(Object.entries(Title))}
        placeholder='Select title'
        rules={[{ required: true }]}
        showSearch={true}
        width={'md'}
        disabled={!canEdit}
        allowClear={true}
        fieldProps={{
          onChange: (value) => {
            updateGender(value)
          },
        }}
      />
      <ProFormSelect
        name='gender'
        label=' Gender '
        valueEnum={currentGender}
        placeholder='Select Gender'
        rules={[{ required: true }]}
        showSearch={true}
        width={'md'}
        disabled={!canEdit}
      />
      <ProFormSelect
        name='race'
        label=' Race '
        valueEnum={Object.fromEntries(Object.entries(Race))}
        placeholder='Select Race'
        rules={[{ required: true }]}
        showSearch={true}
        width={'md'}
        disabled={!canEdit}
      />
      <ProFormSelect
        name='preferredLanguage'
        label=' Prefered language '
        valueEnum={Object.fromEntries(Object.entries(Language))}
        placeholder='Select Preferred Language'
        rules={[{ required: true }]}
        showSearch={true}
        width={'md'}
        disabled={!canEdit}
      />

      <ProFormText
        name='firstNames'
        placeholder={'First names'}
        width='md'
        label='First names'
        rules={[
          {
            required: true,
          },
        ]}
        disabled={!canEdit}
      ></ProFormText>

      <ProFormText
        name='surname'
        placeholder={'Surname'}
        width='md'
        label='Surname'
        rules={[
          {
            required: true,
          },
        ]}
        allowClear={true}
        fieldProps={{}}
        disabled={!canEdit}
      />
      <ProFormText
        name='idNumber'
        placeholder={'ID Number'}
        width='md'
        label='ID Number'
        fieldProps={{ maxLength: 13 }}
        rules={[
          {
            required: true,
            message: 'Please enter a valid ID number',
            pattern:
              /([0-9][0-9])(([0][1-9])|([1][0-2]))(([0-2][0-9])|([3][0-1]))([0-9])([0-9]{3})([0-9])([0-9])([0-9])$/,
          },
        ]}
        disabled={!canEdit}
      />
    </ProForm.Group>
  )
}

export default PersonalInformation
