import ProForm, { ProFormText, ProFormSelect, ProFormDigit } from '@ant-design/pro-form'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Property } from '../../domain/property'
import { WardConsilor } from '../../domain/ward-consilor'
import { Success, Error } from '../../components/notifications'
import { StandType } from '../../domain/stand-type'
import { MunicipalService } from '../../domain/municipal-service'
import { WaterConnectionType } from '../../domain/enums/water-connection-type'
import { PropertyMunicipalServices } from '../../domain/property-municipal-service'
import { Form } from 'antd'
import { Application } from '../../pages/applications/types'

export const formatNumber = (value: string, length?: number) => {
  if (value) {
    const converted = value.replace(/[^\d.-]/g, '')
    return length && converted.length >= length ? converted.substring(0, length) : converted
  }
  return value
}

type PropertyInformationProps = {
  property: Property
  wardConsilors: WardConsilor[]
  standTypes: StandType[]
  customerId?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history?: any
  municipalServices: MunicipalService[]
  updateOfflineApplication: (key: number, values: Application) => Promise<IDBValidKey | undefined>
  offlineApplication?: Application
}

const PropertyInformation = (props: PropertyInformationProps) => {
  const { property, wardConsilors, standTypes, municipalServices } = props
  const { consilor, services } = property

  const [form] = Form.useForm()
  const canEdit = true
  const [currentWardNumber, setWardNumber] = useState(consilor?.wardNumber)

  useEffect(() => {
    if (props.offlineApplication) {
      form.setFieldsValue({
        consilorId: props.offlineApplication.consilorId,
        standTypeId: props.offlineApplication.standTypeId,
        numberOfUnitsOccupants: props.offlineApplication.numberOfUnitsOccupants,
        wardNumber: props.offlineApplication.wardNumber,
        waterConnection: props.offlineApplication.waterConnection,
        servicesId: props.offlineApplication.servicesId,
        eskomPrepaidMeterNo: props.offlineApplication.eskomPrepaidMeterNo,
        accountNumber: props.offlineApplication.accountNumber,
        alternativePowerSource: props.offlineApplication.alternativePowerSource,
      })
    }
  }, [form, props.offlineApplication])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateWardConsilor = (value: any) => {
    const currentWard = wardConsilors.filter((c) => c.id === value)[0]
    if (currentWard) {
      setWardNumber(currentWard.wardNumber)
    }

    return value
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateWardNumber = () => {
    return currentWardNumber
  }
  return (
    <>
      <ProForm
        form={form}
        submitter={{
          submitButtonProps: { hidden: !canEdit },
          resetButtonProps: { hidden: true, style: { display: 'none' } },
          searchConfig: { submitText: 'Save' },
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFinish={async (values: any) => {
          console.log(values)

          try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const offlineApplicationId: string = localStorage.getItem('offline-application-id')
            await props.updateOfflineApplication(parseInt(offlineApplicationId), values)
            Success('Successfully updated offline application !')
          } catch (error) {
            Error(`Error has occured: ${error}`)
          }
        }}
      >
        <ProForm.Group>
          <ProFormSelect
            name='consilorId'
            label=' Ward Councilor '
            placeholder='Select Ward Consilor'
            rules={[{ required: true, message: 'Please select Ward Consilor' }]}
            showSearch={true}
            options={wardConsilors.map((wc) => {
              return {
                value: wc.id,
                label: `${wc.firstNames} ${wc.surname}`,
              }
            })}
            width={'md'}
            disabled={!canEdit}
            convertValue={updateWardConsilor}
          />
          <ProFormSelect
            name='standTypeId'
            label=' Stand Type '
            placeholder='Select Stand Type'
            rules={[{ required: true }]}
            showSearch={true}
            options={standTypes.map((st) => {
              return {
                value: st.id,
                label: `${st.description}`,
              }
            })}
            width={'md'}
            disabled={!canEdit}
          />
          <ProFormDigit
            name='numberOfUnitsOccupants'
            placeholder={'Number Of People Renting'}
            label='Number Of People Renting'
            rules={[
              {
                required: false,
              },
            ]}
            width={'md'}
            disabled={!canEdit}
          />
          <ProFormText
            name='wardNumber'
            placeholder={'Ward Number'}
            label='Ward Number'
            rules={[
              {
                required: false,
              },
            ]}
            width={'md'}
            disabled={!canEdit}
            convertValue={updateWardNumber}
          />
          <ProFormSelect
            name='waterConnection'
            label=' Water Connection '
            valueEnum={Object.fromEntries(Object.entries(WaterConnectionType))}
            placeholder='Select Water Conection'
            rules={[{ required: true }]}
            showSearch={true}
            width={'md'}
            disabled={!canEdit}
          />
          <ProFormSelect.SearchSelect
            name='servicesId'
            label=' Municipal Service '
            placeholder='Select Municipal Service'
            initialValue={services?.map((service: PropertyMunicipalServices) => {
              return {
                value: service.municipalService.id,
                label: `${service.municipalService.name}`,
              }
            })}
            mode={'multiple'}
            showSearch={true}
            options={municipalServices.map((service: MunicipalService) => {
              return {
                value: service.id,
                label: `${service.name}`,
              }
            })}
            width={'md'}
            disabled={!canEdit}
          />
        </ProForm.Group>

        <ProForm.Group>
          <ProFormText
            name='eskomPrepaidMeterNo'
            label='Eskom Meter No '
            rules={[
              {
                required: false,
              },
            ]}
            width={'md'}
            disabled={!canEdit}
          />
          <ProFormText
            name='accountNumber'
            label='Local Municipal Account No '
            rules={[
              {
                required: false,
              },
            ]}
            width={'md'}
            disabled={!canEdit}
            convertValue={(value) => formatNumber(value)}
          />
          <ProFormText
            name='alternativePowerSource'
            label='Alternative Power Source'
            placeholder='Enter alternative power source'
            rules={[
              {
                required: false,
              },
            ]}
            width={'md'}
            disabled={!canEdit}
          />
        </ProForm.Group>
      </ProForm>
    </>
  )
}

PropertyInformation.proptypes = {
  property: PropTypes.object.isRequired,
  wardConsilors: PropTypes.array.isRequired,
  standTypes: PropTypes.array.isRequired,
}

export default PropertyInformation
