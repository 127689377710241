import ProForm, { ProFormText } from '@ant-design/pro-form'

export const formatNumber = (value: string, length?: number) => {
  if (value) {
    const converted = value.replace(/[^\d.-]/g, '')
    return length && converted.length >= length ? converted.substring(0, length) : converted
  }
  return value
}

const ContactInformation = () => {
  const canEdit = true

  return (
    <ProForm.Group>
      <ProFormText
        name='cellphone'
        placeholder={'Cellphone'}
        width='lg'
        label='Cellphone'
        convertValue={(value) => formatNumber(value, 10)}
        rules={[
          {
            required: true,
            message: 'Enter valid cellphone number',
            pattern: /0|27((60[3-9]|64[0-5]|66[0-5])\d{6}|(7[1-4689]|6[1-3]|8[1-4])\d{7})$/,
          },
        ]}
        disabled={!canEdit}
      />
      <ProFormText
        name='postalAddressLine1'
        placeholder={'House / Street Address'}
        width='lg'
        label='Location'
        rules={[
          {
            required: true,
          },
        ]}
        disabled={!canEdit}
      />
      <ProFormText
        name='postalAddressLine2'
        placeholder={'Town or City'}
        width='lg'
        label='Town or City'
        rules={[
          {
            required: true,
          },
        ]}
        disabled={!canEdit}
      />
      <ProFormText
        name='postalAddressLine3'
        placeholder={'Province'}
        width='lg'
        label='Province'
        rules={[
          {
            required: true,
          },
        ]}
        disabled={!canEdit}
      />
      <ProFormText
        name='postalCode'
        placeholder={'Postal Code'}
        width='lg'
        label='Postal Code'
        rules={[
          {
            required: true,
          },
        ]}
        disabled={!canEdit}
      />
    </ProForm.Group>
  )
}

export default ContactInformation
