/**
 * The Language enumeration.
 */
export enum Language {
  ISIXHOSA = 'ISIXHOSA',
  SESOTHO = 'SESOTHO',
  SETSWANA = 'SETSWANA',
  ISIZULU = 'ISIZULU',
  SEPEDI = 'SEPEDI',
  AFRIKAANS = 'AFRIKAANS',
  ENGLISH = 'ENGLISH',
  SWATI = 'SWATI',
  VENDA = 'VENDA',
  NDEBELE = 'NDEBELE',
  TSONGA = 'TSONGA',
}
