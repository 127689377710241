import { Button, Space, Row, Col } from 'antd'
import { HomeFilled } from '@ant-design/icons'
import ProCard from '@ant-design/pro-card'
import { useNavigate } from 'react-router-dom'

type GoBackProps = {
  route: string
}

const GoBack = ({ route }: GoBackProps) => {
  const navigate = useNavigate()

  return (
    <Row gutter={24} style={{ marginBottom: 10 }}>
      <Col span={24}>
        <ProCard layout='default'>
          <Space style={{ width: '100%' }}>
            <Button
              type='primary'
              icon={<HomeFilled />}
              onClick={() => {
                navigate(route)
              }}
            >
              Back
            </Button>
          </Space>
        </ProCard>
      </Col>
    </Row>
  )
}

export default GoBack
