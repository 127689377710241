import React, { useEffect, useState } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import { Table, Button } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { openDB } from 'idb'
import {
  createCustomer,
  createCustomerAssetProfile,
  createApplication,
  fetchWardCounsilors,
  fetchStandTypes,
  fetchMunicipalServices,
  createCustomerProperty,
} from '../../actions/userActions'
import { Application } from './types.d'
import { StatusCodes } from 'http-status-codes'
import { Success, Error } from '../../components/notifications'
import { PlusSquareFilled, DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons'

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstNames',
    key: 'firstNames',
  },
  {
    title: 'Surname',
    dataIndex: 'surname',
    key: 'surname',
  },
  {
    title: 'ID Number',
    dataIndex: 'idNumber',
    key: 'idNumber',
  },
]

const ListApplications: React.FC = () => {
  const [offlineApplications, setOfflineApplications] = useState<Application[]>([])
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine)
  const navigate = useNavigate()

  const handleOnlineStatusChange = () => {
    setIsOnline(navigator.onLine)
  }

  useEffect(() => {
    fetchResources()
    fetchDataFromIndexedDB()
    processApiToken()
    window.addEventListener('online', handleOnlineStatusChange)
    window.addEventListener('offline', handleOnlineStatusChange)
    localStorage.removeItem('offline-application-id')

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange)
      window.removeEventListener('offline', handleOnlineStatusChange)
    }
  }, [])

  useEffect(() => {
    const checkNetworkStatus = setInterval(() => {
      setIsOnline(navigator.onLine)
    }, 5000)

    return () => {
      clearInterval(checkNetworkStatus)
    }
  }, [])

  const processApiToken = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const receivedData = Object.fromEntries(urlParams.entries())
    if (receivedData && receivedData.q) {
      localStorage.setItem('api_token', receivedData.q)
    }

    const apiToken = localStorage.getItem('api_token')

    setTimeout(() => {
      if (apiToken) {
        window.history.replaceState({}, document.title, window.location.pathname)
      }
    }, 3000)
  }

  const deleteFromIndexedDB = async (key: number) => {
    const db = await openDB(process.env.REACT_APP_INDEXEDDB as string, 1)
    const transaction = db.transaction(
      process.env.REACT_APP_INDEXEDDB_STORE_NAME as string,
      'readwrite',
    )
    const store = transaction.objectStore(process.env.REACT_APP_INDEXEDDB_STORE_NAME as string)
    console.log(key)
    await store.delete(key)

    fetchDataFromIndexedDB()
  }

  const fetchDataFromIndexedDB = async () => {
    const db = await openDB(process.env.REACT_APP_INDEXEDDB as string, 1, {
      upgrade(db) {
        db.createObjectStore(process.env.REACT_APP_INDEXEDDB_STORE_NAME as string, {
          autoIncrement: true,
        })
      },
    })

    const transaction = db.transaction(
      process.env.REACT_APP_INDEXEDDB_STORE_NAME as string,
      'readonly',
    )
    const store = transaction.objectStore(process.env.REACT_APP_INDEXEDDB_STORE_NAME as string)
    const values = await store.getAll()
    const keys = await store.getAllKeys()
    const items = keys.map((key, index) => ({ key, ...values[index] }))

    setOfflineApplications(items)
  }

  const sendDataToServer = async (record: Application) => {
    console.log('Sending data to server:', record)

    try {
      const customerResponse = await createCustomer(record)
      console.log(customerResponse)

      if (customerResponse.status === StatusCodes.CREATED) {
        // ts-ignore
        const customerAssetProfileResponse = await createCustomerAssetProfile(
          record,
          customerResponse.data,
        )
        console.log(customerAssetProfileResponse)

        if (customerAssetProfileResponse.status === StatusCodes.CREATED) {
          const applicationResponse = await createApplication(customerResponse.data.id)
          console.log(applicationResponse)

          if (applicationResponse.status === StatusCodes.CREATED) {
            const customerPropertyResponse = await createCustomerProperty(
              record,
              customerResponse.data,
            )

            if (customerPropertyResponse.status === StatusCodes.CREATED) {
              await deleteFromIndexedDB(record.key)
              Success('Successfully sent application to server!')
            }
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      Error(`Error! sending application to server ${error}`)
    }
  }

  const editFromIndexedDB = async (key: number) => {
    navigate(`/application/edit/${key}`)
  }

  const fetchResources = async () => {
    await fetchWardCounsilors()
    await fetchStandTypes()
    await fetchMunicipalServices()
  }

  const content = (
    <div>
      <Table
        dataSource={offlineApplications}
        columns={[
          ...columns,
          {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            /* eslint-disable @typescript-eslint/no-explicit-any */
            render: (_: any, record: Application) => (
              <>
                <Button
                  type='link'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    deleteFromIndexedDB(record.key)
                  }}
                >
                  Delete
                </Button>
                <Button
                  type='link'
                  icon={<EditOutlined />}
                  onClick={() => {
                    editFromIndexedDB(record.key)
                  }}
                >
                  Edit
                </Button>

                {isOnline && (
                  <Button
                    type='link'
                    icon={<SyncOutlined />}
                    onClick={() => {
                      sendDataToServer(record)
                    }}
                  >
                    Sync Data
                  </Button>
                )}
              </>
            ),
          },
        ]}
      />
    </div>
  )

  return (
    <PageContainer
      header={{
        title: 'OFFLINE APPLICATIONS',
        ghost: true,
        breadcrumb: {},
        extra: [
          <Link to='/application/add' key='1'>
            <Button type='primary' icon={<PlusSquareFilled />}>
              Capture New Application
            </Button>
          </Link>,
        ],
      }}
      footer={[]}
    >
      {content}
    </PageContainer>
  )
}

export default ListApplications
