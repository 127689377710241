import { notification } from 'antd/lib/'
import { SmileOutlined, CloseCircleOutlined } from '@ant-design/icons'

export const Success = (message: string) => {
  notification.success({
    message: 'Successful',
    description: message,
    icon: <SmileOutlined style={{ color: 'green' }} />,
  })
}

export const Error = (message: string) => {
  notification.error({
    message: 'Error',
    description: message,
    icon: <CloseCircleOutlined style={{ color: 'red' }} />,
  })
}
