/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react'
import ProForm from '@ant-design/pro-form'
import ProCard from '@ant-design/pro-card'
import { AssetProfile } from '../../components/asset-profile'
import { Success, Error } from '../../components/notifications'
import { Application } from '../../pages/applications/types'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'antd'

type Props = {
  hideSaveBtn: boolean
  offlineApplication?: Application
  updateOfflineApplication: (key: number, values: Application) => Promise<IDBValidKey | undefined>
}

const AssetProfileForm = (props: Props) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    if (props.offlineApplication) {
      form.setFieldsValue({
        propertyStand: props.offlineApplication.propertyStand,
        propertyNumberOfGarages: props.offlineApplication.propertyNumberOfGarages,
        propertyNumberOfRooms: props.offlineApplication.propertyNumberOfRooms,
        propertyWallType: props.offlineApplication.propertyWallType,
        propertyRoofingType: props.offlineApplication.propertyRoofingType,
        propertyNumberOfRentedRooms: props.offlineApplication.propertyNumberOfRentedRooms,
        otherNumberOfVehicles: props.offlineApplication.otherNumberOfVehicles,
        ownsAgricultureEquipment: props.offlineApplication.ownsAgricultureEquipment,
        hasDSTV: props.offlineApplication.hasDSTV,
        liveStockCount: props.offlineApplication.liveStockCount,
        cellphonesCount: props.offlineApplication.cellphonesCount,
      })
    }
  }, [form, props.offlineApplication])

  return (
    <ProCard layout='default'>
      <ProForm
        form={form}
        submitter={{
          submitButtonProps: {
            hidden: false,
            style: {
              display: props.hideSaveBtn ? 'none' : '',
            },
          },
          resetButtonProps: { hidden: true, style: { display: 'none' } },
          searchConfig: { submitText: 'Save' },
        }}
        onFinish={async (values) => {
          console.log(values)

          try {
            // @ts-ignore
            const offlineApplicationId: string = localStorage.getItem('offline-application-id')
            const updateOfflineApplication = await props.updateOfflineApplication(
              parseInt(offlineApplicationId),
              values,
            )

            console.log(updateOfflineApplication)
            Success('Successfully updated offline application !')

            if (!id) {
              navigate('/')
            }
          } catch (error) {
            Error(`Error has occured: ${error}`)
          }
        }}
      >
        <AssetProfile />
      </ProForm>
    </ProCard>
  )
}

export default AssetProfileForm
