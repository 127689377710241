import React, { useState, useEffect } from 'react'
import ProCard from '@ant-design/pro-card'
import ProForm from '@ant-design/pro-form'
import PersonalInformation from '../customer/PersonalInformation'
import { openDB } from 'idb'
import ContactInformation from '../customer/ContactInformation'
import { Success } from '../notifications'
import { Application } from '../../pages/applications/types'
import { Form } from 'antd'
import { useParams } from 'react-router-dom'

type Props = {
  offlineApplication?: Application
  hideSaveBtn: boolean
  updateOfflineApplication: (key: number, values: Application) => Promise<IDBValidKey | undefined>
}

const CustomerDetailsForm = (props: Props) => {
  const [form] = Form.useForm()
  const [offlineApplications, setOfflineApplications] = useState<Application[]>([])
  const { id } = useParams<{ id: string }>()

  console.log(offlineApplications)

  useEffect(() => {
    checkOfflineApplications()
  }, [])

  useEffect(() => {
    if (props.offlineApplication) {
      form.setFieldsValue({
        firstNames: props.offlineApplication?.firstNames,
        title: props.offlineApplication?.title,
        gender: props.offlineApplication?.gender,
        race: props.offlineApplication?.race,
        preferredLanguage: props.offlineApplication?.preferredLanguage,
        surname: props.offlineApplication?.surname,
        idNumber: props.offlineApplication?.idNumber,
        cellphone: props.offlineApplication?.cellphone,
        postalAddressLine1: props.offlineApplication.postalAddressLine1,
        postalAddressLine2: props.offlineApplication.postalAddressLine2,
        postalAddressLine3: props.offlineApplication.postalAddressLine3,
        postalCode: props.offlineApplication.postalCode,
      })
    }
  }, [form, props.offlineApplication])

  const checkOfflineApplications = async () => {
    const db = await openDB(process.env.REACT_APP_INDEXEDDB as string, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(process.env.REACT_APP_INDEXEDDB_STORE_NAME as string)) {
          db.createObjectStore(process.env.REACT_APP_INDEXEDDB_STORE_NAME as string, {
            autoIncrement: true,
          })
        }
      },
    })

    const existingOfflineApplications: Application[] = await db.getAll(
      process.env.REACT_APP_INDEXEDDB_STORE_NAME as string,
    )
    setOfflineApplications(existingOfflineApplications)
  }

  const saveOfflineApplications = async (data: Application, key?: string) => {
    console.log(key)

    if (key) {
      const updateOfflineApplication = await props.updateOfflineApplication(parseInt(key), data)
      console.log(updateOfflineApplication)
    } else {
      const db = await openDB(process.env.REACT_APP_INDEXEDDB as string, 1)
      const applicationID = await db.add(process.env.REACT_APP_INDEXEDDB_STORE_NAME as string, data)

      localStorage.setItem('offline-application-id', applicationID as string)

      checkOfflineApplications()
    }
  }

  const displayBtn = () => {
    let flag = false

    if (id) {
      flag = true
    }

    if (localStorage.getItem('offline-application-id') === null) {
      flag = true
    }
    return flag
  }

  return (
    <ProCard layout='default'>
      <ProForm
        form={form}
        submitter={{
          submitButtonProps: {
            hidden: true,
            style: {
              display: displayBtn() ? '' : 'none',
            },
          },
          resetButtonProps: { hidden: true, style: { display: 'none' } },
          searchConfig: { submitText: 'Save', resetText: 'Clear' },
        }}
        onFinish={async (values) => {
          console.log(values)

          if (navigator.onLine) {
            console.log('I m online')
            // await sendDataToServer(formData);
            await saveOfflineApplications(values, id)

            Success('Successfully saved application offline!')
          } else {
            console.log('I m offline')
            await saveOfflineApplications(values)

            Success('Successfully saved application offline!')
          }
        }}
      >
        <>
          <ProCard bordered={true} direction={'row'} layout='default' title='Personal Information'>
            <PersonalInformation />
          </ProCard>
          <ProCard
            bordered={true}
            direction={'row'}
            layout='default'
            title='Contact Information'
            style={{ margin: '20px 0 20px 0px' }}
          >
            <ContactInformation />
          </ProCard>
        </>
      </ProForm>
    </ProCard>
  )
}

export default CustomerDetailsForm
