import { AxiosResponse } from 'axios'
import indigentApi from '../apis/indigentApi'
import { Application } from '../pages/applications/types.d'
import { openDB } from 'idb'
import { StatusCodes } from 'http-status-codes'

export const getInitials = (firstNames: string) => {
  if (firstNames)
    return String(firstNames.split(' ').map((name: string) => name[0])).replace(',', '')
  return ''
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createCustomer = async (record: Application): Promise<AxiosResponse<any>> => {
  const apiToken = localStorage.getItem('api_token')

  if (!apiToken) {
    return Promise.reject(new Error('No Access Token Found'))
  }

  if (!navigator.onLine) {
    return Promise.reject(new Error('No internet connection'))
  }

  const config = {
    headers: { Authorization: `Bearer ${apiToken}` },
  }

  const payload = {
    title: record.title,
    gender: record.gender,
    race: record.race,
    preferredLanguage: record.preferredLanguage,
    firstNames: record.firstNames,
    surname: record.surname,
    idNumber: record.idNumber,
    initials: getInitials(record.firstNames),
    postalAddressLine1: record.postalAddressLine1,
    postalAddressLine2: record.postalAddressLine2,
    postalAddressLine3: record.postalAddressLine3,
    postalCode: record.postalCode,
    homeTelephone: record.homeTelephone,
    workTelephone: record.workTelephone,
    faxTelephone: record.faxTelephone,
    cellphone: record.cellphone,
  }

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await indigentApi.post('/customers', payload, config)
    console.log(response)
    return response
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createCustomerAssetProfile = async (
  record: Application,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customer: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any>> => {
  const apiToken = localStorage.getItem('api_token')

  console.log(record)
  console.log(customer)

  if (!apiToken) {
    return Promise.reject(new Error('No Access Token Found'))
  }

  if (!navigator.onLine) {
    return Promise.reject(new Error('No internet connection'))
  }

  const config = {
    headers: { Authorization: `Bearer ${apiToken}` },
  }

  const payload = {
    customer,
    propertyStand: record.propertyStand,
    propertyNumberOfGarages: record.propertyNumberOfGarages,
    propertyNumberOfRooms: record.propertyNumberOfRooms,
    propertyWallType: record.propertyWallType,
    propertyRoofingType: record.propertyRoofingType,
    propertyNumberOfRentedRooms: record.propertyNumberOfRentedRooms,
    otherNumberOfVehicles: record.otherNumberOfVehicles,
    ownsAgricultureEquipment: record.ownsAgricultureEquipment,
    hasDSTV: record.hasDSTV,
    liveStockCount: record.liveStockCount,
    cellphonesCount: record.cellphonesCount,
  }

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await indigentApi.post('/customer-asset-profiles', payload, config)
    console.log(response)
    return response
  } catch (error) {
    console.log(error)
    throw error
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
export const createApplication = async (customerId: number): Promise<AxiosResponse<any>> => {
  const apiToken = localStorage.getItem('api_token')

  if (!apiToken) {
    return Promise.reject(new Error('No Access Token Found'))
  }

  if (!navigator.onLine) {
    return Promise.reject(new Error('No internet connection'))
  }

  const config = {
    headers: { Authorization: `Bearer ${apiToken}` },
  }

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await indigentApi.post(`/applications/customer/${customerId}`, {}, config)
    console.log(response)
    return response
  } catch (error) {
    console.log(error)
    throw error
  }
}

async function openDatabase() {
  const db = await openDB(process.env.REACT_APP_INDEXEDDB_RESOURCES as string, 1, {
    upgrade(db) {
      if (
        !db.objectStoreNames.contains(
          process.env.REACT_APP_INDEXEDDB_WARD_COUNSILORS_STORE_NAME as string,
        )
      ) {
        db.createObjectStore(process.env.REACT_APP_INDEXEDDB_WARD_COUNSILORS_STORE_NAME as string, {
          autoIncrement: true,
        })
      }
      if (
        !db.objectStoreNames.contains(
          process.env.REACT_APP_INDEXEDDB_STAND_TYPES_STORE_NAME as string,
        )
      ) {
        db.createObjectStore(process.env.REACT_APP_INDEXEDDB_STAND_TYPES_STORE_NAME as string, {
          autoIncrement: true,
        })
      }
      if (
        !db.objectStoreNames.contains(
          process.env.REACT_APP_INDEXEDDB_MUNICIPAL_SERVICES_STORE_NAME as string,
        )
      ) {
        db.createObjectStore(
          process.env.REACT_APP_INDEXEDDB_MUNICIPAL_SERVICES_STORE_NAME as string,
          {
            autoIncrement: true,
          },
        )
      }
    },
  })
  return db
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchWardCounsilors = async (): Promise<any[]> => {
  const apiToken = localStorage.getItem('api_token')

  if (!apiToken) {
    return Promise.reject(new Error('No Access Token Found'))
  }

  if (!navigator.onLine) {
    const db = await openDatabase()

    const wardCounsilors = db.getAll(
      process.env.REACT_APP_INDEXEDDB_WARD_COUNSILORS_STORE_NAME as string,
    )

    return Promise.resolve(wardCounsilors)
  }

  const config = {
    headers: { Authorization: `Bearer ${apiToken}` },
  }

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await indigentApi.get('/ward-consilors', config)

    if (response.status === StatusCodes.OK) {
      const db = await openDatabase()

      const transaction = db.transaction(
        process.env.REACT_APP_INDEXEDDB_WARD_COUNSILORS_STORE_NAME as string,
        'readwrite',
      )
      const store = transaction.objectStore(
        process.env.REACT_APP_INDEXEDDB_WARD_COUNSILORS_STORE_NAME as string,
      )

      await store.put(response.data.data, 0)

      const wardCounsilors = db.getAll(
        process.env.REACT_APP_INDEXEDDB_WARD_COUNSILORS_STORE_NAME as string,
      )

      return Promise.resolve(wardCounsilors)
    }
    return Promise.resolve([])
  } catch (error) {
    console.log(error)
    throw error
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchStandTypes = async (): Promise<any[]> => {
  const apiToken = localStorage.getItem('api_token')

  if (!apiToken) {
    return Promise.reject(new Error('No Access Token Found'))
  }

  if (!navigator.onLine) {
    const db = await openDatabase()

    const standTypes = db.getAll(process.env.REACT_APP_INDEXEDDB_STAND_TYPES_STORE_NAME as string)

    return Promise.resolve(standTypes)
  }

  const config = {
    headers: { Authorization: `Bearer ${apiToken}` },
  }

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await indigentApi.get('/stand-types', config)

    if (response.status === StatusCodes.OK) {
      const db = await openDatabase()

      const transaction = db.transaction(
        process.env.REACT_APP_INDEXEDDB_STAND_TYPES_STORE_NAME as string,
        'readwrite',
      )
      const store = transaction.objectStore(
        process.env.REACT_APP_INDEXEDDB_STAND_TYPES_STORE_NAME as string,
      )

      await store.put(response.data, 0)

      const standTypes = db.getAll(process.env.REACT_APP_INDEXEDDB_STAND_TYPES_STORE_NAME as string)

      return Promise.resolve(standTypes)
    }
    return Promise.resolve([])
  } catch (error) {
    console.log(error)
    throw error
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchMunicipalServices = async (): Promise<any[]> => {
  const apiToken = localStorage.getItem('api_token')

  if (!apiToken) {
    return Promise.reject(new Error('No Access Token Found'))
  }

  if (!navigator.onLine) {
    const db = await openDatabase()

    const municipalServices = db.getAll(
      process.env.REACT_APP_INDEXEDDB_MUNICIPAL_SERVICES_STORE_NAME as string,
    )

    return Promise.resolve(municipalServices)
  }

  const config = {
    headers: { Authorization: `Bearer ${apiToken}` },
  }

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await indigentApi.get('/municipal-services', config)

    if (response.status === StatusCodes.OK) {
      const db = await openDatabase()

      const transaction = db.transaction(
        process.env.REACT_APP_INDEXEDDB_MUNICIPAL_SERVICES_STORE_NAME as string,
        'readwrite',
      )
      const store = transaction.objectStore(
        process.env.REACT_APP_INDEXEDDB_MUNICIPAL_SERVICES_STORE_NAME as string,
      )

      await store.put(response.data.data, 0)

      const municipalServices = db.getAll(
        process.env.REACT_APP_INDEXEDDB_MUNICIPAL_SERVICES_STORE_NAME as string,
      )

      return Promise.resolve(municipalServices)
    }
    return Promise.resolve([])
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createCustomerProperty = async (
  record: Application,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customer: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any>> => {
  const apiToken = localStorage.getItem('api_token')

  console.log(record)
  console.log(customer)

  if (!apiToken) {
    return Promise.reject(new Error('No Access Token Found'))
  }

  if (!navigator.onLine) {
    return Promise.reject(new Error('No internet connection'))
  }

  const config = {
    headers: { Authorization: `Bearer ${apiToken}` },
  }

  const municipalServices = record?.servicesId?.map((service) => {
    return {
      active: true,
      municipalService: {
        id: service.value,
      },
    }
  })

  const payload = {
    customer,
    numberOfUnitsOccupants: record.numberOfUnitsOccupants,
    waterConnection: record.waterConnection,
    eskomPrepaidMeterNo: record.eskomPrepaidMeterNo,
    accountNumber: record.accountNumber,
    alternativePowerSource: record.alternativePowerSource,
    wardNumber: record.wardNumber,
    gpsCoordinates: record.gpsCoordinates,
    consilor: { id: record.consilorId },
    standType: { id: record.standTypeId },
    services: municipalServices,
  }

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await indigentApi.post('/properties', payload, config)
    console.log(response)
    return response
  } catch (error) {
    console.log(error)
    throw error
  }
}
