import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ListApplications from './pages/applications/list'
import CaptureApplication from './pages/applications/add'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ListApplications />} />
        <Route path='/application/add' element={<CaptureApplication />} />
        <Route path='/application/edit/:id' element={<CaptureApplication />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
