import React, { useState, useEffect } from 'react'
import { PageContainer, ProCard } from '@ant-design/pro-components'
import { Row, Col } from 'antd'
import CustomerDetailsForm from '../../components/applications/CustomerDetailsForm'
import AssetProfileForm from '../../components/applications/AssetProfileForm'
import PropertyInformationForm from '../../components/applications/PropertyInformationForm'
import ApplicationDocumentsForm from '../../components/applications/ApplicationDocumentsForm'
import { Link, useParams } from 'react-router-dom'
import GoBack from '../../components/common/back'
import { openDB } from 'idb'
import { Application } from './types'
import {
  fetchWardCounsilors,
  fetchStandTypes,
  fetchMunicipalServices,
} from '../../actions/userActions'
import { WardConsilor } from '../../domain/ward-consilor'
import { StandType } from '../../domain/stand-type'
import { MunicipalService } from '../../domain/municipal-service'

type CustomerDetailsTabProps = {
  offlineApplication?: Application
  hideSaveBtn: boolean
  updateOfflineApplication: (key: number, values: Application) => Promise<IDBValidKey | undefined>
}

const CustomerDetailsTab = (props: CustomerDetailsTabProps) => {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <ProCard>
          <CustomerDetailsForm
            offlineApplication={props.offlineApplication}
            hideSaveBtn={props.hideSaveBtn}
            updateOfflineApplication={props.updateOfflineApplication}
          />
        </ProCard>
      </Col>
    </Row>
  )
}

type AssetProfileTabProps = {
  hideSaveBtn: boolean
  offlineApplication?: Application
  updateOfflineApplication: (key: number, values: Application) => Promise<IDBValidKey | undefined>
}

const AssetProfileTab = (props: AssetProfileTabProps) => {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <ProCard>
          <AssetProfileForm
            hideSaveBtn={props.hideSaveBtn}
            offlineApplication={props.offlineApplication}
            updateOfflineApplication={props.updateOfflineApplication}
          />
        </ProCard>
      </Col>
    </Row>
  )
}

type PropertyInformationProps = {
  updateOfflineApplication: (key: number, values: Application) => Promise<IDBValidKey | undefined>
  wardCounsilors: WardConsilor[]
  standTypes: StandType[]
  municipalServices: MunicipalService[]
  offlineApplication?: Application
}
const PropertyInformationTab = (props: PropertyInformationProps) => {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <ProCard>
          <PropertyInformationForm
            wardCounsilors={props.wardCounsilors}
            updateOfflineApplication={props.updateOfflineApplication}
            standTypes={props.standTypes}
            municipalServices={props.municipalServices}
            offlineApplication={props.offlineApplication}
          />
        </ProCard>
      </Col>
    </Row>
  )
}

const ApplicationDocumentsTab = () => {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <ProCard>
          <ApplicationDocumentsForm />
        </ProCard>
      </Col>
    </Row>
  )
}

// eslint-disable-next-line react/display-name
const CaptureApplication: React.FC = () => {
  const [hideSaveBtn, setHideSaveBtn] = useState(true)
  const { id } = useParams<{ id: string }>()
  console.log(id)
  const [offlineApplication, setOfflineApplication] = useState(undefined)
  const [wardCounsilors, setWardCounsilors] = useState([])
  const [standTypes, setStandTypes] = useState([])
  const [municipalServices, setMunicipalServices] = useState([])

  useEffect(() => {
    fetchResources()
    if (id) {
      fetchOfflineApplication(id)
    }
  }, [])

  const fetchResources = async () => {
    const wardCounsilors = await fetchWardCounsilors()
    setWardCounsilors(wardCounsilors[0])

    const standTypes = await fetchStandTypes()
    setStandTypes(standTypes[0])

    const municipalServices = await fetchMunicipalServices()
    setMunicipalServices(municipalServices[0])
  }

  const fetchOfflineApplication = async (key: string) => {
    const db = await openDB(process.env.REACT_APP_INDEXEDDB as string, 1)
    const transaction = db.transaction(
      process.env.REACT_APP_INDEXEDDB_STORE_NAME as string,
      'readwrite',
    )
    const store = transaction.objectStore(process.env.REACT_APP_INDEXEDDB_STORE_NAME as string)
    console.log(key)
    const offlineApplication = await store.get(parseInt(key))

    setOfflineApplication(offlineApplication)

    localStorage.setItem('offline-application-id', key as string)

    console.log(offlineApplication)
  }

  const getOfflineApplication = async (key: number) => {
    try {
      const db = await openDB(process.env.REACT_APP_INDEXEDDB as string, 1)
      const transaction = db.transaction(
        process.env.REACT_APP_INDEXEDDB_STORE_NAME as string,
        'readonly',
      )
      const store = transaction.objectStore(process.env.REACT_APP_INDEXEDDB_STORE_NAME as string)
      const offlineApplication = await store.get(key)

      console.log(offlineApplication)

      return offlineApplication
    } catch (error) {
      console.log(error)
      Error(`Error has occured ${error}`)
    }
  }

  const updateOfflineApplication = async (key: number, values: Application) => {
    try {
      const offlineApplication = await getOfflineApplication(key)

      if (!offlineApplication) {
        throw Error('No Offline Application Found')
      }

      const newData = { ...offlineApplication, ...values }
      const db = await openDB(process.env.REACT_APP_INDEXEDDB as string, 1)
      const transaction = db.transaction(
        process.env.REACT_APP_INDEXEDDB_STORE_NAME as string,
        'readwrite',
      )
      const store = transaction.objectStore(process.env.REACT_APP_INDEXEDDB_STORE_NAME as string)

      const updateOfflineApplication = store.put(newData, key)

      return updateOfflineApplication
    } catch (error) {
      console.log(error)
      Error(`Error has occured ${error}`)
    }
  }

  const handleOnClick = () => {
    setHideSaveBtn(localStorage.getItem('offline-application-id') === null)
  }
  return (
    <div
      style={{
        background: '#F5F7FA',
      }}
    >
      <PageContainer
        header={{
          title: id ? 'Edit Application' : 'New Application',
          ghost: true,
          breadcrumb: {
            items: [
              {
                title: <Link to='/'>Offline Applications</Link>,
              },
              {
                title: id ? 'Edit Application' : 'Capture New Application',
              },
            ],
          },
          extra: [<GoBack route='/' key={0} />],
        }}
        tabList={[
          {
            tab: 'Customer Details',
            key: 'customer-details',
            closable: false,
            children: (
              <CustomerDetailsTab
                offlineApplication={offlineApplication}
                hideSaveBtn={hideSaveBtn}
                updateOfflineApplication={updateOfflineApplication}
              />
            ),
          },
          {
            tab: 'Asset Profile',
            key: 'asset-profile',
            closable: false,
            children: (
              <AssetProfileTab
                hideSaveBtn={hideSaveBtn}
                offlineApplication={offlineApplication}
                updateOfflineApplication={updateOfflineApplication}
              />
            ),
          },
          {
            tab: 'Property Information',
            key: 'property-info',
            closable: false,
            children: (
              <PropertyInformationTab
                wardCounsilors={wardCounsilors}
                updateOfflineApplication={updateOfflineApplication}
                standTypes={standTypes}
                municipalServices={municipalServices}
                offlineApplication={offlineApplication}
              />
            ),
            disabled: id ? false : true,
          },
        ]}
        tabProps={{
          type: 'editable-card',
          hideAdd: true,
          onEdit: (e, action) => console.log(e, action),
          onClick: () => handleOnClick(),
        }}
        footer={[]}
      ></PageContainer>
    </div>
  )
}

export default CaptureApplication
