import React from 'react'
import ProForm, {
  ProFormText,
  ProFormSelect,
  ProFormDigit,
  ProFormCheckbox,
} from '@ant-design/pro-form'
import ProCard from '@ant-design/pro-card'

type CustomerAssetProfileProps = {
  offlineApplicationId?: string | null
}

const AssetProfile = (props: CustomerAssetProfileProps) => {
  console.log(props)
  const canEdit = true

  return (
    <>
      <ProCard bordered={true} direction={'row'} title={'Asset profile'}>
        <ProForm.Group title={'Property Details'}>
          <ProFormText
            name='propertyStand'
            placeholder={'Property Stand'}
            label='Property Stand'
            rules={[
              {
                required: true,
              },
            ]}
            disabled={!canEdit}
          />
          <ProFormDigit
            name='propertyNumberOfGarages'
            placeholder={'Number Of Garages'}
            label='Number Of Garages'
            rules={[
              {
                required: true,
                type: 'integer',
              },
            ]}
            disabled={!canEdit}
          />
          <ProFormDigit
            name='propertyNumberOfRooms'
            placeholder={'Number Of Rooms'}
            label='Number Of Rooms'
            rules={[
              {
                required: true,
                type: 'integer',
              },
            ]}
            disabled={!canEdit}
          />
          <ProFormSelect
            name='propertyWallType'
            label=' Property Wall '
            valueEnum={{
              MUD: 'Mud',
              BLOCK: 'Block',
              BRICK: 'Brick',
              ZINC: 'Zinc',
            }}
            placeholder='Select Property Walls'
            rules={[{ required: true }]}
            disabled={!canEdit}
          />
          <ProFormSelect
            name='propertyRoofingType'
            label=' Property Roofing '
            valueEnum={{
              THATCH: 'Thatch',
              TILE: 'Tile',
              ZINC: 'Zinc',
            }}
            placeholder='Select Property Roofing'
            rules={[{ required: true }]}
            disabled={!canEdit}
          />
          <ProFormDigit
            name='propertyNumberOfRentedRooms'
            placeholder={'Number Of Rented Rooms'}
            label='Number Of Rented Rooms'
            rules={[
              {
                required: true,
                type: 'integer',
              },
            ]}
            disabled={!canEdit}
          />
        </ProForm.Group>
      </ProCard>
      <br />
      <ProCard bordered={true} direction={'row'}>
        <ProForm.Group title={'Other Asset'}>
          <ProFormDigit
            name='otherNumberOfVehicles'
            placeholder={'Number Of Vehicles'}
            label='Number Of Vehicles Owned'
            rules={[
              {
                required: true,
                type: 'integer',
              },
            ]}
            disabled={!canEdit}
          />
          <ProFormCheckbox
            name='ownsAgricultureEquipment'
            placeholder={'Owns'}
            label='Owns Agriculture Equipment'
            disabled={!canEdit}
          />
          <ProFormCheckbox
            name='hasDSTV'
            placeholder={'Property Stand'}
            label='Has DSTV'
            disabled={!canEdit}
          />
          <ProFormDigit
            name='liveStockCount'
            placeholder={'Total Livestock Owned'}
            label='Total Livestock Owned'
            rules={[
              {
                required: true,
                type: 'integer',
              },
            ]}
            disabled={!canEdit}
          />
          <ProFormDigit
            name='cellphonesCount'
            placeholder={'Number Of Cellphones Owned'}
            label='Number Of Cellphones Owned'
            rules={[
              {
                required: true,
              },
            ]}
            disabled={!canEdit}
          />
        </ProForm.Group>
      </ProCard>
    </>
  )
}

export default AssetProfile
