/**
 * The Gender enumeration.
 */
export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum MaleGenderEnum {
  MALE = 'MALE',
}

export enum FemaleGenderEnum {
  FEMALE = 'FEMALE',
}
